import PeopleRoot from "../components/peopleroot";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { normalize_graphql_data } from "../components/utils";

const PeopleRootPage = ({ props, data, pageContext }) => {
  let target_people = normalize_graphql_data(data.target.nodes);
  let other_books = normalize_graphql_data(data.other_books.edges);
  let other_people = normalize_graphql_data(data.other_people.edges);

  let area = pageContext.slug;
  let paginationData = {
    currentPage: pageContext.currentPage,
    numPages: pageContext.numPages,
    base: `/setor/${area}`,
  };

  return (
    <Layout>
      <PeopleRoot
        people={target_people}
        other_books={other_books}
        other_people={other_people}
        area={area}
        pagination={paginationData}
      />
    </Layout>
  );
};

export const query = graphql`
  query SetorQuery($slug: String, $skip: Int, $limit: Int) {
    other_people: allPeepsJson(
      limit: 12
      skip: 40
      sort: { fields: person_ranking }
    ) {
      edges {
        node {
          ...PeepsJsonFragment
        }
      }
    }
    other_books: allBeeksJson(
      limit: 12
      skip: 30
      sort: { fields: recommended_volume, order: DESC }
    ) {
      edges {
        node {
          ...BeeksJsonFragment
        }
      }
    }
    target: allPeepsJson(
      limit: $limit
      skip: $skip
      filter: { attributes_slugs: { glob: $slug } }
      sort: { fields: person_name, order: ASC }
    ) {
      totalCount
      nodes {
        ...PeepsJsonFragmentComplete
      }
    }
  }
`;

export default PeopleRootPage;
